<script>
import {
  // FacebookIcon,
  // InstagramIcon,
  // TwitterIcon,
  // LinkedinIcon,
  MailIcon,
} from "vue-feather-icons";
import axios from "axios";
import { API_URL, MEDIA_URL } from "@/core/api";
export default {
  data() {
    return {
      // isCondensed: false,
      apiUrl: API_URL,
      auth: localStorage.getItem("id_token") !== null ? true : false,
      recruiter:
        localStorage.getItem("user") !== null &&
        JSON.parse(localStorage.getItem("user")).role == "Direct Employer"
          ? true
          : false,
      jobSeeker:
        localStorage.getItem("user") !== null &&
        JSON.parse(localStorage.getItem("user")).role == "Job Seeker"
          ? true
          : false,
      isCondensed: false,
    };
  },
  components: {
    // FacebookIcon,
    // InstagramIcon,
    // TwitterIcon,
    // LinkedinIcon,
    MailIcon,
  },
  mounted() {
    axios.get(`${this.apiUrl}/get-top-job-cities`).then((response) => {
      this.cities = response.data;
    });
  },
  methods: {
    toggleMenu() {
      this.isCondensed = !this.isCondensed;
      if (this.isCondensed) {
        document.getElementById("navigation").style.display = "block";
      } else document.getElementById("navigation").style.display = "none";
    },

    search(item) {
      this.$router.replace({
        path: "/jobs/Pakistan/" + item.city_name,
        params: {
          search_text: this.search_text,
          selected_cities: [{ id: 1, value: item.city_name }],
        },
      });
    },
  },
};
</script>

<template>
  <div class="mt-17">
    <div>
      <!-- Navbar STart -->
      <header
        id="topnav"
        class="defaultscroll sticky"
        :class="{ 'bg-white': isWhiteNavbar === true }"
      >
        <div class="container">
          <!-- Logo container-->
          <div>
            <router-link class="logo" to="/" v-if="navLight !== true">
              <img src="/images/logo-dark.svg" height="50" alt="" />
            </router-link>
            <router-link class="logo" to="/" v-else>
              <img
                src="/images/logo-dark.svg"
                class="l-dark"
                height="50"
                alt=""
              />
              <img
                src="/images/logo-dark.svg"
                class="l-light"
                height="24"
                alt=""
              />
            </router-link>
          </div>
          <div class="buy-button" style="width: 28% text-align: end;">
            <a
              v-if="auth"
              class="btn auth_btn_ch2 dropdown"
              :class="{
                'btn-light': navLight === true,
                'btn-primary': navLight !== true,
              }"
              >My Account
              <div class="dropdown-content">
                <router-link
                  to="/dashboard"
                  class="btn auth_btn_ch3"
                  style="
                    margin-left: 3%;
                    background: transparent !important;
                    color: #000000 !important;
                  "
                  >Dashboard</router-link
                >
                <a
                  @click="onLogout"
                  class="btn auth_btn_ch3"
                  style="
                    margin-left: 3%;
                    background: transparent !important;
                    color: #000000 !important;
                  "
                  >Logout</a
                >
              </div>
            </a>
            <a
              v-if="!auth"
              href="/login"
              class="btn auth_btn_ch1"
              :class="{
                'btn-light': navLight === true,
                'btn-primary': navLight !== true,
              }"
              >Login</a
            >
            <a
              v-if="!auth"
              href="/recruiter-registeration"
              class="btn auth_btn_ch2"
              :class="{
                'btn-light': navLight === true,
                'btn-warning': navLight !== true,
              }"
              style="margin-left: 3%"
              >Register</a
            >
            <a
              v-if="!auth || recruiter"
              href="/recruit-top-talent-in-pakistan"
              class="btn auth_btn_ch3"
              style="margin-left: 3%; background: #a9250f; color: white"
              >Recruiters &nbsp;&nbsp;<i
                class="fa fa-caret-right"
                style="color: white"
              ></i
            ></a>

            <a
              v-if="auth && jobSeeker"
              href="/dashboard"
              class="btn auth_btn_ch4"
              style="margin-left: 3%; background: #a9250f; color: white"
            >
              Job Seekers &nbsp;&nbsp;<i
                class="fa fa-caret-right"
                style="color: white"
              ></i
            ></a>
          </div>

          <!--end login button-->
          <!-- End Logo container-->
          <div class="menu-extras">
            <div class="menu-item">
              <!-- Mobile menu toggle-->
              <a
                class="navbar-toggle"
                @click="toggleMenu()"
                :class="{ open: isCondensed === true }"
              >
                <div class="lines">
                  <span></span>
                  <span></span>
                  <span></span>
                </div>
              </a>
              <!-- End mobile menu toggle-->
            </div>
          </div>

          <div id="navigation">
            <!-- Navigation Menu-->
            <ul
              class="navigation-menu"
              :class="{ 'nav-light': navLight === true }"
            >
              <li>
                <router-link to="/" class="side-nav-link-ref">Home</router-link>
              </li>
              <li>
                <router-link to="/jobs-in-pakistan" class="side-nav-link-ref"
                  >Jobs</router-link
                >
              </li>
              <li>
                <router-link to="/career-resources" class="side-nav-link-ref"
                  >Career Resources</router-link
                >
              </li>
              <div class="ml-10 mb-10 hide_on_dectop_ch">
                <a
                  v-if="auth"
                  class="btn auth_btn_ch2 dropdown"
                  :class="{
                    'btn-light': navLight === true,
                    'btn-primary': navLight !== true,
                  }"
                  >My Account
                  <div class="dropdown-content">
                    <router-link
                      to="/dashboard"
                      class="btn auth_btn_ch3"
                      style="
                        margin-left: 3%;
                        background: transparent !important;
                        color: #000000 !important;
                      "
                      >Dashboard</router-link
                    >
                    <a
                      @click="onLogout"
                      class="btn auth_btn_ch3"
                      style="
                        margin-left: 3%;
                        background: transparent !important;
                        color: #000000 !important;
                      "
                      >Logout</a
                    >
                  </div>
                </a>
                <a
                  v-if="!auth"
                  href="/login"
                  class="btn auth_btn_ch1"
                  :class="{
                    'btn-light': navLight === true,
                    'btn-primary': navLight !== true,
                  }"
                  >Login</a
                >
                <a
                  v-if="!auth"
                  href="/recruiter-registeration"
                  class="btn auth_btn_ch2"
                  :class="{
                    'btn-light': navLight === true,
                    'btn-warning': navLight !== true,
                  }"
                  style="margin-left: 3%"
                  >Register</a
                >
                <a
                  v-if="!auth || recruiter"
                  href="/recruit-top-talent-in-pakistan"
                  class="btn auth_btn_ch3"
                  style="margin-left: 3%; background: #a9250f; color: white"
                  >Recruiters &nbsp;&nbsp;<i
                    class="fa fa-caret-right"
                    style="color: white"
                  ></i
                ></a>

                <a
                  v-if="auth && jobSeeker"
                  href="/dashboard"
                  class="btn auth_btn_ch4"
                  style="margin-left: 3%; background: #a9250f; color: white"
                >
                  Job Seekers &nbsp;&nbsp;<i
                    class="fa fa-caret-right"
                    style="color: white"
                  ></i
                ></a>
              </div>
            </ul>
          </div>
        </div>
      </header>
      <!--end header-->
      <!-- Navbar End -->
    </div>
    <div class="login_image_bg">
      <div class="container" style="margin: 0 auto">
        <div
          class="login login-1"
          :class="{
            'login-signin-on': this.state == 'signin',
          }"
          id="kt_login"
        >
          <div class="row align-items-center">
            <!--begin::Aside-->
            <div class="col-lg-4 auth_heading">
              Welcome <span class="font-weight-bolder"> Back..!</span>
            </div>
            <!--begin::Aside-->
            <!--begin::Content-->
            <div
              class="col-lg-5 col-md-6 bg-white p-9 px-10 border Small shadow"
            >
              <div class="">
                <div class="">
                  <div class="login-form login-signin">
                    <form
                      class="form"
                      novalidate="novalidate"
                      id="kt_login_signin_form"
                      @submit.stop.prevent="onSubmitLogin()"
                    >
                      <div class="pt-lg-0 pt-5">
                        <div class="card-title auth_title">
                          <h1 class="auth_title">Set New Password</h1>
                          <p
                            class="text-muted text-center"
                            style="font-size: 12px"
                          >
                            Follow the instructions to set your new password
                          </p>
                        </div>
                      </div>
                      <div class="form-group">
                        <div
                          id="example-input-group-1"
                          label=""
                          label-for="example-input-1"
                          class="form-group auth_inputs_img position-relative"
                        >
                          <img
                            src="/images/login_contact/password.svg"
                            alt=""
                          />
                          <input
                            class="form-control fieldch"
                            type="password"
                            name="email_password"
                            placeholder="Enter Email Code"
                            ref="email_password"
                            autocomplete="off"
                            v-model="form.email"
                          />
                        </div>
                      </div>
                      <div class="form-group auth_inputs_img position-relative">
                        <img src="/images/login_contact/password.svg" alt="" />
                        <input
                          class="form-control fieldch"
                          placeholder="Enter New Password"
                          name="password"
                          ref="rpassword"
                          autocomplete="off"
                          :type="showPassword === false ? 'text' : 'password'"
                        />
                        <span
                          @click="toggleShow"
                          class="icon is-small is-right password_show_ch"
                        >
                          <i
                            :class="
                              showPassword === false
                                ? 'fas fa-eye-slash'
                                : 'fas fa-eye'
                            "
                          ></i>
                        </span>
                      </div>
                      <div class="form-group auth_inputs_img position-relative">
                        <img src="/images/login_contact/password.svg" alt="" />
                        <input
                          class="form-control fieldch"
                          placeholder="Retype Password"
                          name="cpassword"
                          ref="cpassword"
                          autocomplete="off"
                          :type="showPassword === false ? 'text' : 'password'"
                        />
                        <span
                          @click="toggleShow"
                          class="icon is-small is-right password_show_ch"
                        >
                          <i
                            :class="
                              showPassword === false
                                ? 'fas fa-eye-slash'
                                : 'fas fa-eye'
                            "
                          ></i>
                        </span>
                      </div>
                      <div class="pb-lg-0 pb-5">
                        <button
                          ref="kt_login_signin_submit"
                          class="btn submitBnt"
                        >
                          Reset Password
                        </button>
                      </div>
                      <div class="hr_blue"></div>
                      <div
                        class="text-muted text-center"
                        style="font-size: 13px !important"
                      >
                        Remamber your password?
                        <a
                          id=""
                          class="text-primary font-weight-bolder"
                          href="/login"
                        >
                          Login</a
                        >
                      </div>
                    </form>
                  </div>
                </div>
                <!--begin::Content footer-->
                <div class="text-center pt-8">
                  <a
                    href="/terms-and-conditions"
                    class="font-weight-bold font-size-h6"
                    >Terms</a
                  >
                  <a
                    href="/recruit-top-talent-in-pakistan"
                    class="ml-10 font-weight-bold font-size-h6"
                    >Plans</a
                  >
                  <a
                    href="/contact-us"
                    class="ml-10 font-weight-bold font-size-h6"
                    >Contact Us</a
                  >
                </div>
                <!--end::Content footer-->
              </div>
            </div>
            <!--end::Content-->
          </div>
        </div>
      </div>
    </div>
    <div>
      <!-- Footer Start -->
      <footer class="footer mt-15">
        <div class="container">
          <div class="row">
            <div class="col-lg-4 col-md-4 col-12 mt-4 mt-sm-0 pt-2 pt-sm-0">
              <h4 class="text-light footer-head">Jobs by city</h4>
              <div style="display: flex">
                <ul class="list-unstyled footer-list mt-4 small-text-mb">
                  <li>
                    <span>
                      <i class="mdi mdi-chevron-right mr-1"></i>
                      <a href="/jobs/Pakistan/Lahore"> Lahore</a>
                    </span>
                  </li>
                  <li>
                    <span>
                      <i class="mdi mdi-chevron-right mr-1"></i>
                      <a href="/jobs/Pakistan/Faisalabad"> Faisalabad</a>
                    </span>
                  </li>
                  <li>
                    <span>
                      <i class="mdi mdi-chevron-right mr-1"></i>
                      <a href="/jobs/Pakistan/Rawalpindi"> Rawalpindi</a>
                    </span>
                  </li>
                  <li>
                    <span>
                      <i class="mdi mdi-chevron-right mr-1"></i>
                      <a href="/jobs/Pakistan/Karachi"> Karachi</a>
                    </span>
                  </li>
                  <li>
                    <span>
                      <i class="mdi mdi-chevron-right mr-1"></i>
                      <a href="/jobs/Pakistan/Hyderabad"> Hyderabad</a>
                    </span>
                  </li>
                </ul>
                <ul class="list-unstyled footer-list mt-4 small-text-mb ml-20">
                  <li>
                    <span>
                      <i class="mdi mdi-chevron-right mr-1"></i>
                      <a href="/jobs/Pakistan/Peshawar"> Peshawar</a>
                    </span>
                  </li>
                  <li>
                    <span>
                      <i class="mdi mdi-chevron-right mr-1"></i>
                      <a href="/jobs/Pakistan/Quetta"> Quetta</a>
                    </span>
                  </li>
                  <li>
                    <span>
                      <i class="mdi mdi-chevron-right mr-1"></i>
                      <a href="/jobs/Pakistan/Sialkot"> Sialkot</a>
                    </span>
                  </li>
                  <li>
                    <span>
                      <i class="mdi mdi-chevron-right mr-1"></i>
                      <a href="/jobs/Pakistan/Sheikhupurah"> Sheikhupurah</a>
                    </span>
                  </li>
                  <li>
                    <span>
                      <i class="mdi mdi-chevron-right mr-1"></i>
                      <a href="/jobs/Pakistan/Sargodha"> Sargodha</a>
                    </span>
                  </li>
                </ul>
              </div>
            </div>
            <!--end col-->

            <div
              class="
                col-lg-2 col-md-4 col-6
                mt-6 mt-sm-0
                pt-2 pt-sm-0
                small-text-mb
              "
              style="padding-right: 2px"
            >
              <h4 class="text-light footer-head">Employers</h4>
              <ul class="list-unstyled footer-list mt-4">
                <li>
                  <a href="/login" class="text-foot"
                    ><i class="mdi mdi-chevron-right mr-1"></i> Login</a
                  >
                </li>
                <li>
                  <a href="/recruiter-registeration" class="text-foot"
                    ><i class="mdi mdi-chevron-right mr-1"></i> Create
                    account</a
                  >
                </li>
                <li>
                  <router-link to="/recruiter/jobs/create" class="text-foot"
                    ><i class="mdi mdi-chevron-right mr-1"></i> Post a
                    job</router-link
                  >
                </li>
                <li v-if="auth && recruiter">
                  <router-link to="/dashboard" class="text-foot"
                    ><i class="mdi mdi-chevron-right mr-1"></i> Search
                    candidates</router-link
                  >
                </li>
                <li>
                  <router-link to="/contact-us" class="text-foot"
                    ><i class="mdi mdi-chevron-right mr-1"></i>
                    Contact</router-link
                  >
                </li>
              </ul>
            </div>
            <!--end col-->

            <div
              class="
                col-lg-3 col-md-4 col-6
                mt-6 mt-sm-0
                pt-2 pt-sm-0
                small-text-mb
              "
            >
              <h4 class="text-light footer-head">Job seekers</h4>
              <ul class="list-unstyled footer-list mt-4">
                <li>
                  <a href="/login" class="text-foot"
                    ><i class="mdi mdi-chevron-right mr-1"></i> Login</a
                  >
                </li>
                <li>
                  <a href="/job-seeker-register" class="text-foot"
                    ><i class="mdi mdi-chevron-right mr-1"></i> Create
                    account</a
                  >
                </li>
                <li>
                  <router-link to="/contact-us" class="text-foot"
                    ><i class="mdi mdi-chevron-right mr-1"></i>
                    Contact</router-link
                  >
                </li>
                <li>
                  <router-link to="/recruiter-directory" class="text-foot"
                    ><i class="mdi mdi-chevron-right mr-1"></i> Recruiter
                    directory</router-link
                  >
                </li>
              </ul>
            </div>
            <!--end col-->

            <div class="col-lg-3 col-md-4 col-12 mt-4 mt-sm-0 pt-2 pt-sm-0">
              <h4 class="text-light footer-head">Newsletter</h4>
              <p class="mt-4 small-text-mb">
                Sign up for latest news and industry updates
              </p>
              <form>
                <div class="row">
                  <div class="col-lg-12">
                    <div class="foot-subscribe form-group position-relative">
                      <!-- <label
                        >Write your email
                        <span class="text-danger">*</span></label
                      > -->
                      <mail-icon class="fea icon-sm icons"></mail-icon>
                      <input
                        type="email"
                        name="email"
                        id="emailsubscribe"
                        class="form-control pl-5 rounded"
                        placeholder="Your email : "
                        required
                      />
                    </div>
                  </div>
                  <div class="col-lg-12">
                    <input
                      type="submit"
                      id="submitsubscribe"
                      name="send"
                      class="btn btn-soft-primary btn-block"
                      value="Subscribe"
                    />
                  </div>
                </div>
              </form>
              <ul class="list-unstyled social-icon social mb-0 mt-2 text-start">
                <li class="list-inline-item">
                  <a href="javascript:void(0)" class="rounded">
                    <img
                      src="/images/login_contact/Facebook.svg"
                      height="12"
                      alt=""
                    />
                  </a>
                </li>
                <li class="list-inline-item ml-1">
                  <a href="javascript:void(0)" class="rounded">
                    <img
                      src="/images/login_contact/Instagram.svg"
                      height="12"
                      alt=""
                    />
                  </a>
                </li>
                <li class="list-inline-item ml-1">
                  <a
                    href="https://www.instagram.com/wwwjob4upk/"
                    class="rounded"
                  >
                    <img
                      src="/images/login_contact/Twitter.svg"
                      height="12"
                      alt=""
                    />
                    <!-- <twitter-icon class="fea icon-sm fea-social"></twitter-icon> -->
                  </a>
                </li>
                <li class="list-inline-item ml-1">
                  <a href="#" class="rounded">
                    <img
                      src="/images/Whatsapp.svg"
                      width="15"
                      height="15"
                      alt="..."
                      class=""
                    />
                  </a>
                </li>
              </ul>
            </div>
            <!--end col-->
          </div>
          <!--end row-->
        </div>
        <!--end container-->
      </footer>
      <!--end footer-->
      <footer class="footer footer-bar">
        <div class="container text-center">
          <div class="row align-items-center">
            <div class="col-sm-6">
              <div class="text-sm-left">
                <p class="mb-0">© job4u.pk 2022.</p>
              </div>
            </div>
            <div class="col-sm-6 col-12 row m-0">
              <div class="text-sm-right col-lg-8 col-5 p-0">
                <a href="/privacy-policy" class="ml-2 text-primary"
                  >Privacy Policy</a
                >
              </div>
              <div class="text-sm-right col-lg-4 col-7 p-0">
                <a href="/terms-and-conditions" class="ml-2 text-primary"
                  >Terms and Conditions</a
                >
              </div>
            </div>
            <!--end col-->
          </div>
          <!--end row-->
        </div>
        <!--end container-->
      </footer>
      <!--end footer-->
    </div>
  </div>
</template>

<!-- Load login custom page styles -->
<style lang="scss">
@import "@/assets/sass/pages/login/login-1.scss";
</style>

<script>
import formValidation from "@/assets/plugins/formvalidation/dist/es6/core/Core";

// FormValidation plugins
import Trigger from "@/assets/plugins/formvalidation/dist/es6/plugins/Trigger";
import Bootstrap from "@/assets/plugins/formvalidation/dist/es6/plugins/Bootstrap";
import SubmitButton from "@/assets/plugins/formvalidation/dist/es6/plugins/SubmitButton";

import KTUtil from "@/assets/js/components/util";
import { mapGetters, mapState } from "vuex";
import { LOGIN, LOGOUT, REGISTER } from "@/core/services/store/auth.module";
import Swal from "sweetalert2";
import axios from "axios";
import { API_URL } from "@/core/api";
import { initFbsdk } from "@/core/config/facebook.js";

export default {
  name: "login-1",
  data() {
    return {
      // recruiterSignUp: false,
      // agencySignUp: false,
      state: "signin",
      // auth: localStorage.getItem("id_token") !== null ? true : false,
      // Remove this dummy login info
      form: {
        email: "",
        password: "",
      },
      // cities: [],
      apiUrl: API_URL,
      showPassword: true,
      // search_text: "",
      // selected_cities: [],
    };
  },
  computed: {
    ...mapState({
      errors: (state) => state.auth.errors,
    }),
    ...mapGetters(["currentUser"]),

    backgroundImage() {
      return (
        process.env.BASE_URL + "media/svg/illustrations/login-visual-1.svg"
      );
    },
  },
  mounted() {
    initFbsdk();
    const signin_form = KTUtil.getById("kt_login_signin_form");
    this.fv1 = formValidation(signin_form, {
      fields: {
        email_password: {
          validators: {
            notEmpty: {
              message: "Email code is required",
            },
          },
        },
        password: {
          validators: {
            notEmpty: {
              message: "Password is required",
            },
          },
        },
        cpassword: {
          validators: {
            notEmpty: {
              message: "Confirm password is required",
            },
            identical: {
              compare: function () {
                return signin_form.querySelector('[name="password"]').value;
              },
              message: "Passwords do not match",
            },
          },
        },
      },
      plugins: {
        trigger: new Trigger(),
        submitButton: new SubmitButton(),
        bootstrap: new Bootstrap(),
      },
    });
  },
  methods: {
    showForm(form) {
      this.state = form;
      var form_name = "kt_login_" + form + "_form";
      KTUtil.animateClass(
        KTUtil.getById(form_name)
        // "animate__animated animate__backInUp"
      );
    },
    toggleShow() {
      this.showPassword = !this.showPassword;
    },
    onSubmitLogin() {
      this.fv1.validate();
      this.fv1.validate().then((res) => {
        if (res == "Valid") {
          const submitButton = this.$refs["kt_login_signin_submit"];
          submitButton.classList.add(
            "spinner",
            "spinner-light",
            "spinner-right"
          );
          const email_password = this.$refs.email_password.value;
          const rpassword = this.$refs.rpassword.value;
          axios
            .post(`${API_URL}/set-password`, {
              email_password: email_password,
              rpassword: rpassword,
            })
            .then((data) => {
              const submitButton = this.$refs["kt_login_signin_submit"];
              submitButton.classList.remove(
                "spinner",
                "spinner-light",
                "spinner-right"
              );
              if (data.data === "set password") {
                Swal.fire({
                  title: "",
                  text: "Your password was set successfully. You are now signed in",
                  icon: "success",
                  confirmButtonClass: "btn btn-secondary",
                  heightAuto: false,
                }).then(function () {
                  window.location = "/login";
                });
              } else {
                if (data.data === "Email code is not correct") {
                  Swal.fire({
                    title: "",
                    text: "Your Email Code in not correct please make sure your email code",
                    icon: "error",
                    confirmButtonClass: "btn btn-secondary",
                    heightAuto: false,
                  });
                } else {
                  Swal.fire({
                    title: "",
                    text: "Have some issues please contact with job4u.pk sport team",
                    icon: "error",
                    confirmButtonClass: "btn btn-secondary",
                    heightAuto: false,
                  });
                }
              }
            });
        } else {
          const submitButton = this.$refs["kt_login_signin_submit"];
          submitButton.classList.remove(
            "spinner",
            "spinner-light",
            "spinner-right"
          );
          Swal.fire({
            title: "",
            text: "Please, provide correct data!",
            icon: "error",
            confirmButtonClass: "btn btn-secondary",
            heightAuto: false,
          });
        }
      });
      this.fv.on("core.form.invalid", () => {
        Swal.fire({
          title: "",
          text: "Please, provide correct data!",
          icon: "error",
          confirmButtonClass: "btn btn-secondary",
          heightAuto: false,
        });
      });
    },
  },
};
</script>
<style scoped>
footer span,
footer a,
footer p,
footer h4,
footer label {
  color: white !important;
}
</style>